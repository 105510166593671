import { Rolle } from '../api/queries/user/domain';

import { Optional } from '../utils/tsutils';

import { Bod } from './Bod';
import { Parkeringsplass } from './Parkeringsplass';
import { IdType, ISODateTimeString, UrlType } from './common';
import { Beboer, entityFullName } from './Beboer';

export interface FolkeregistrertAdresse {
  gateadresse?: string;
  postnummer?: string;
  poststed?: string;
}

export interface Person {
  attendances: {
    id: IdType;
    boligselskapId: IdType;
    boligId: IdType;
    beboer: Beboer;
    validFrom: ISODateTimeString;
    validTo: ISODateTimeString;
  }[];
  bildeURL?: UrlType;
  birthDay?: string;
  boder?: Bod[];
  completeMobile?: string;
  country: string;
  epost?: string;
  etternavn?: string;
  folkeregisteret?: FolkeregistrertAdresse;
  fornavn?: string;
  gateadresse?: string;
  historicRoles: Rolle[];
  id: IdType;
  invoicingEmail?: string;
  isUser: boolean;
  kontonummer?: string;
  mobil?: string;
  mobilCountryCode?: string;
  nationality: IdType;
  notat?: string;
  parkeringsplasser?: Parkeringsplass[];
  passportExpiration?: ISODateTimeString;
  passportNumberId?: IdType;
  personnummer?: string;
  personnummerId?: string;
  personnummerTwoId?: string;
  postnummer?: string;
  poststed?: string;
  postland?: IdType;
  reservertEpost?: boolean;
  reservertSMS?: boolean;
  roles: Rolle[];
  tittel?: string;
  userId?: string;
}

export interface EditPersonParams {
  account?: string;
  area?: string;
  birthDay?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  historicRoles: Rolle[];
  id?: IdType;
  invoicingEmail?: string;
  notat?: string;
  personnummer?: string;
  phone?: string;
  postCountry?: IdType;
  profileURL?: string;
  reservedEmail?: boolean;
  reservedSMS?: boolean;
  roles: Rolle[];
  street?: string;
  title?: string;
  zipCode?: string;
}

export interface MergePersonResult {
  account?: string;
  area?: string;
  birthDay?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  id?: IdType;
  invoicingEmail?: string;
  personnummer?: string;
  phone?: string;
  profileURL?: string;
  reservedEmail?: boolean;
  reservedSMS?: boolean;
  street?: string;
  title?: string;
  zipCode?: string;
}

export interface Personnummer {
  personnummer: string;
}

export interface IdentificationNumber {
  identificationNumber: string;
}

export interface PersonnummerSearchName {
  family: string;
  given: string;
  middle: string;
}

export interface PersonnummerSearchAdresseRoadNumber {
  nr: string;
}

export interface PersonnummerSearchAdresseRoadPostalArea {
  name: string;
  number: string;
}

export interface PersonnummerSearchAdresseRoad {
  name: string;
  number: PersonnummerSearchAdresseRoadNumber;
  postalArea: PersonnummerSearchAdresseRoadPostalArea;
}

export interface PersonnummerSearchAdresse {
  road: PersonnummerSearchAdresseRoad;
}

export interface PersonnummerSearchDeath {
  active: boolean;
  dateOfIncident?: string;
  reason?: string;
  source?: string;
}

export interface PersonnummerSearch {
  adresse: PersonnummerSearchAdresse[];
  death?: PersonnummerSearchDeath;
  identificationNumber: IdentificationNumber[];
  name: PersonnummerSearchName[];
}

export type PersonWithOptionalId = Optional<Person, 'id'>;

export const epostRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

export const telefonRegex = /^(0{2}[1-9]\d{1,3}|\+[1-9]\d{1,2}|)([1-9]\d{7,10})$/;
export const norwayPhoneRegex = /^(0{2}[1-9]\d{1,2}|\+[1-9]\d{1,2}|)([1-9]\d{7})$/;
export const universalPostcodeRegex =
  /^[A-Za-z\d]{3,10}([-\s][A-Za-z\d]{3,10})?([-\s][A-Za-z\d]{1,10})?$/;
export const norwayPostcodeRegex = /^\d{4}$/;

export const personnummerRegex = /^\d{11}$/;
export const kontonummerRegex = /^\d{11}$/;

export const getFulltNavnOrEpost = (
  person?: Pick<Person, 'epost' | 'fornavn' | 'etternavn'>
): string => {
  return person
    ? person.fornavn && person.etternavn
      ? `${person.fornavn} ${person.etternavn}`
      : person.fornavn || person.epost || ''
    : '';
};

export const byFulltNavn = () => (a: Person, b: Person) =>
  getFulltNavnOrEpost(a) > getFulltNavnOrEpost(b)
    ? 1
    : getFulltNavnOrEpost(b) > getFulltNavnOrEpost(a)
      ? -1
      : 0;

export const byBeboerNavn = () => (a: Beboer, b: Beboer) =>
  entityFullName(a) > entityFullName(b) ? 1 : entityFullName(b) > entityFullName(a) ? -1 : 0;
